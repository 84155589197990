<template>
    <div class="modal is-active">
        <div @click.self="$emit('toggle_order_transport_create_modal')" class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Order transport create / edit</p>
                <button @click.prevent="$emit('toggle_order_transport_create_modal')" class="delete" aria-label="close"></button>
            </header>
            <section class="modal-card-body p-0">
                <div class="is-flex is-flex-direction-column">
                    <div class="p-2 bg-green-100">
                        <p v-if="!show_create_form" @click="toggle_create_new_form" class="has-text-centered">
                            <i class="fa-solid fa-plus"></i>
                        </p>
                        <div v-else>
                            <div class="is-flex is-justify-content-space-between">
                                <span>
                                    Create transport job
                                </span>
                                <span @click="toggle_create_new_form">
                                    <i class="fa-solid fa-xmark mr-1"></i>
                                </span>
                            </div>
                            <div>
                                form...
                            </div>
                        </div>
                    </div>
                    <div class="p-2">
                        
                        <div class="p-1 pl-2 bg-sky-50 border border-sky-200 mb-2">
                            <small class="text-sky-700">
                                Some info
                            </small>
                        </div>
                        <table class="table is-narrow is-fullwidth is-hoverable">
                            <tbody>
                                <tr>
                                    <td width="60px">
                                        <span class="fa-stack " style="vertical-align: top;">
                                            <i class="fa-regular fa-circle fa-stack-2x text-slate-300"></i>
                                            <i style="font-size: 12px;" class="fa-solid fa-truck fa-stack-1x text-slate-400"></i>
                                        </span>
                                    </td>
                                    <td>
                                        List item
                                    </td>
                                </tr>
                                <tr>
                                    <td width="60px">
                                        <span class="fa-stack " style="vertical-align: top;">
                                            <i class="fa-regular fa-circle fa-stack-2x text-slate-300"></i>
                                            <i style="font-size: 12px;" class="fa-solid fa-truck fa-stack-1x text-slate-400"></i>
                                        </span>
                                    </td>
                                    <td>
                                        List item
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                
            </section>
            <footer class="modal-card-foot">
                
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show_create_form: false
        }
    },
    methods: {
        toggle_create_new_form(){
            this.show_create_form = !this.show_create_form
        }
    },
}
</script>

<style>

</style>