<template>
    <div class=" ">
        <div v-if="$parent.$parent.$parent.pos_loading">
            <i class="fa fa-spinner fa-spin"></i>
        </div>
        <div v-else>
            <!-- <div v-if="!show_edit_pos && document.draft_info.pos_id" class="">
                <span v-if="document.assigned_pos_list.length > 0">
                    {{document.assigned_pos_list.find(p => p.guid == document.draft_info.pos_id).name}}
                </span>
                <a @click.prevent="change_pos" class="ml-2" href="">
                    <i class="fa-solid fa-pencil"></i>
                </a>
            </div> -->
            <div >
                <p v-if="document.assigned_pos_list.length == 0">
                    Nuk jeni i/e rregjistruar ne asnje POS
                </p>
                <div v-else>
                    <div class="field has-addons">
                        <div class="control is-expanded">
                            <div class="select is-fullwidth is-small">
                                <select @change="pos_changed()" v-model="document[document.current_doc_type + '_info'].pos_id" name="country">
                                    <option  value="">Zgjidh pos</option>
                                    <option v-for="(pos, index) in document.assigned_pos_list" :key="index"
                                        :value="pos.guid">{{pos.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="control">
                            <button @click.prevent="select_pos" type="submit" class="button is-success is-small"><i class="fa-solid fa-check"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Api from "@/services/Api";
import { storeToRefs } from 'pinia';
// import { usePosStore } from "@/stores/pos";
import { useDocumentStore } from "@/stores/document";
export default {
    data() {
        return {
            show_edit_pos: true,
            // store_pos: storeToRefs(usePosStore()),
            document: storeToRefs(useDocumentStore()),
            
        }
    },
    methods: {
        select_pos(){
            this.document.assigned_pos_list.map(p => {
                if(p.guid == this.document[this.document.current_doc_type + '_info'].pos_id){
                    this.document[this.document.current_doc_type + '_info'].pos_id = p.guid
                    // this.document.selected_pos = p
                }
            })
            this.show_edit_pos = false
        },
        change_pos(){
            this.show_edit_pos = true
        },
        pos_changed(){
            var found = false
            this.document.assigned_pos_list.map(p => {
                if(p.guid == this.document[this.document.current_doc_type + '_info'].pos_id){
                    this.document[this.document.current_doc_type + '_info'].pos_id = p.guid
                    this.document.selected_pos = p
                    found = true
                }
            })
            if(found == false){
                this.document.selected_pos = {}
            }
            this.document.assigned_pos_warehouses = []
            this.document.selected_pos_warehouse = {}
            //Get POS assigned warehouses
            if(this.document.selected_pos.guid){
                Api(true).post('/pos/warehouses/list', {pos_id: this.document.selected_pos.guid})
                .then(rr => {
                    this.document.assigned_pos_warehouses = rr.data
                    this.document.selected_pos_warehouse = {}
                })
            }

        }
    },
    

}
</script>