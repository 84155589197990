<template>
    <div>
        <!-- DRAFT TABLE -->
        <table v-if="document.current_doc_type == 'draft'"
            class="table is-fullwidth is-hoverable is-size-6 document-table">
            <thead>
                <tr style="font-family: 'Karla', sans-serif;">
                    <th width="80px">#</th>
                    <th v-if="$route.params.document">Statusi</th>
                    <th>POS</th>
                    <th>Krijuar nga</th>
                    <th>Klienti</th>
                    <th>Krijuar me</th>
                </tr>
            </thead>
            <tbody>
                <tr @click.prevent="open_doc(docs)" v-for="docs in document_list" :key="docs.id" class="is-clickable">
                    <td>{{ docs.id }}</td>
                    <td :class="
                        docs.status_id == 0 ? 'has-text-info' :
                            docs.status_id == 1 ? 'has-text-danger' :
                                docs.status_id == 2 ? 'has-text-success' : ''
                    ">
                        {{
                                docs.status_id == 0 ? 'E re' :
                                    docs.status_id == 1 ? 'Per komfirmim cmimi' :
                                        docs.status_id == 2 ? 'Cmim i konfirmuar' : ''
                        }}
                    </td>
                    <td>
                        {{ docs.pos_name }}
                    </td>
                    <td>
                        {{ docs.created_by_name }}
                    </td>
                    <td>{{ docs.customer_name }}</td>
                    <td>
                        {{ docs.created_at ? date_filter(docs.created_at) : '' }}
                        <!-- {{docs.created_at}} -->
                    </td>
                </tr>
            </tbody>
        </table>


        <!-- ORDER TABLE -->
        <!-- <pre>
            {{document_list}}
        </pre> -->
        <table v-if="document.current_doc_type == 'order'"
            class="table is-fullwidth is-hoverable is-size-6 document-table">
            <thead>
                <tr style="font-family: 'Karla', sans-serif;">
                    <th width="80px">ID</th>
                    <th>POS</th>
                    <th>Magazina</th>
                    <th>Klienti</th>
                    <th>Krijuar</th>
                    <th>
                        <i class="fa-solid fa-truck mr-3 text-slate-400"></i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(doc, index) in document_list">
                    <tr v-if="doc" :key="index + ' head'" class="bg-slate-50 is-clickable">
                        <td @click="open_doc(doc, 1)">
                            {{ doc.draft_number }}
                        </td>
                        <td @click="open_doc(doc, 1)">
                            {{ doc.pos_name }}
                        </td>
                        <td @click="open_doc(doc, 1)">
                            {{ doc.warehouse_name }}
                        </td>
                        <td @click="open_doc(doc, 1)">{{ doc.customer_name }}</td>
                        <td @click="open_doc(doc, 1)" class="py-1">
                            <div style="font-size:11px;">
                                <p>
                                    <span class="text-slate-300">
                                        <i class="fa-solid fa-calendar-days"></i>:
                                    </span>
                                    {{ doc.created_at ? date_filter(doc.created_at) : '' }}
                                </p>
                                <p>
                                    <span class="text-slate-300">
                                        <i class="fa-regular fa-user"></i>:
                                    </span>
                                    {{ doc.created_by_name }}
                                </p>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>
                                    <i class="fa-solid fa-check text-green-500"></i>
                                </span>
                                <span class="text-slate-200">
                                    <i class="fa-solid fa-truck-moving mx-5"></i>
                                </span>
                                <span @click="toggle_order_transport_create_modal">
                                    <i class="fa-solid fa-pen text-slate-300"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr :key="index + ' sub ' + index2" v-for="(d, index2) in doc.details" class="is-clickable">
                        <!-- <td class="pl-4"> ------ {{doc.id}}</td>  -->
                        <td @click="open_doc(d)" class="pl-4 ">
                            <div class="is-flex">
                                <span class="arrow-icon mr-2">
                                    &#8629;
                                </span>
                                <span class="fraction-wrap">
                                    {{ d.fraction }}
                                </span>
                            </div>
                        </td>
                        <td @click="open_doc(d)"></td>
                        <td @click="open_doc(d)">
                            <span v-if="d.warehouse_guid != '[POS-LOCATION]'">
                                {{ d.warehouse_name }}
                            </span>
                            <span v-else>
                                <i>[Ne vend]</i>
                            </span>
                        </td>
                        <td @click="open_doc(d)">{{ d.customer_name }}</td>
                        <td @click="open_doc(d)" class="py-1">
                            <div style="font-size:11px;">
                                <p>
                                    <span class="text-slate-300">
                                        <i class="fa-solid fa-calendar-days"></i>:
                                    </span>
                                    {{ d.created_at ? date_filter(d.created_at) : '' }}
                                </p>
                                <p>
                                    <span class="text-slate-300">
                                        <i class="fa-regular fa-user"></i>:
                                    </span>
                                    {{ d.created_by_name }}
                                </p>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>
                                    <i class="fa-solid fa-check text-green-500"></i>
                                </span>
                                <span>
                                    <i class="fa-solid fa-truck-moving mx-5 text-slate-200"></i>
                                </span>
                                <span @click="toggle_order_transport_create_modal">
                                    <i class="fa-solid fa-pen text-slate-300"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
        <order-transport-create-modal v-if="show_order_transport_create_modal"
            @toggle_order_transport_create_modal="toggle_order_transport_create_modal"></order-transport-create-modal>
    </div>
</template>
<script>
import DocumentServices from "@/services/Document";
import { useDocumentStore } from "@/stores/document";
import { storeToRefs } from 'pinia';
import moment from 'moment'
import permissionMixin from '@/mixins/permissions-mixin.js'
import Api from "@/services/Api";
import orderTransportCreateModal from "./order-transport-create-modal";
// import socket from "@/socket.js";
// import { onUnmounted,/* reactive, inject*/ } from "vue";
export default {
    mixins: [permissionMixin],
    components: {
        orderTransportCreateModal
    },
    data() {
        return {
            // documents: storeToRefs(useDocumentStore()),
            selectedDoc: [],
            document: storeToRefs(useDocumentStore()),
            document_type: 'draft',
            show_order_transport_create_modal: false
        }
    },
    async created() {

        this.document.list = await DocumentServices.getPosDocuments(this.document.current_doc_type);
        this.moment = moment;
        this.document_type = this.document.current_doc_type
    },
    methods: {
        async open_doc(doc, is_order_head = 0) {
            // evaluate if order has suborders
            // if(doc.has_suborders){
            //     this.document[this.document_type + '_cart'] = []
            //     this.document[this.document_type + '_infos'] = []
            //     for(const d of doc.details){
            //         this.selectedDoc = await DocumentServices.getDoc({...d, document_type: this.document.current_doc_type})
            //         this.document[this.document_type + '_info'] = this.selectedDoc.info
            //         this.document[this.document_type + '_infos'] = [...this.document[this.document_type + '_infos'], ...[this.selectedDoc.info]]
            //         this.document[this.document_type + '_cart'] = [...this.document[this.document_type + '_cart'], ...this.selectedDoc.details]
            //         this.document['selected_customer'] = this.selectedDoc.selected_customer
            //     }


            //     this.$vfm.hideAll()

            //     // await this.get_documents_notes()

            // }
            // else {
            //     doc.document_type = this.document.current_doc_type
            //     this.selectedDoc = await DocumentServices.getDoc(doc)
            //     this.document[this.document_type + '_info'] = this.selectedDoc.info
            //     this.document[this.document_type + '_cart'] = this.selectedDoc.details
            //     this.document['selected_customer'] = this.selectedDoc.selected_customer
            //     this.$vfm.hideAll()
            //     await this.get_documents_notes()
            // }

            // 

            if (is_order_head != 0) {
                window.location.href = '/document_details/' + this.$route.params.document + '/' + doc.guid + '/' + is_order_head

                // this.$router.push({ path: '/document_details/' + this.$route.params.document + '/' + doc.guid + '/' + is_order_head})
            }
            else {
                window.location.href = '/document_details/' + this.$route.params.document + '/' + doc.guid
                // this.$router.push({ path: '/document_details/' + this.$route.params.document + '/' + doc.guid })
            }

            // setTimeout(() => {
            //     window.location.reload();
            // }, 500);
        },
        async get_documents_notes() {
            await Api(true).post('/note/get/data', {
                guid: this.document[this.document.current_doc_type + '_info'].guid,
                document_type: this.$route.params.document
            })
            .then(r => {
                // this.notes = {...r.data, edit_mode: false}
                this.document.notes = [...r.data.map(n => {
                    n.edit_mode = false
                    n.original_content = n.content
                    return n
                })]
            })
        },
        date_filter(date) {
            return moment(date).format('DD/MM/YYYY hh:mm')
        },
        toggle_order_transport_create_modal() {
            this.show_order_transport_create_modal = !this.show_order_transport_create_modal
        },
        groupBy(xs, key) {
            return xs.reduce( (rv, x) =>  {
                if(this.document.in_store == 1 && x.warehouse_guid == '[POS-LOCATION]'){
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                }
                else if(this.document.in_store == 0){
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                }
                return rv;
            }, {});
        }
    },
    computed: {
        document_list() {
            let user = JSON.parse(localStorage.user)
            let list = this.document.list.filter(d => {
                if (this.document[this.document.current_doc_type + '_info'].pos_id) {
                    if (this.document[this.document.current_doc_type + '_info'].pos_id == d.pos_id && (user.id == d.created_by || this.am_allowed(this.$route.params.document + '.can_manage_others'))) return true
                    else return false
                } else {
                    if (this.am_allowed('draft.can_confirm_discount')) return true
                    else return false
                }
            })
            // If it is order -> group by guid
            if (this.document.current_doc_type == 'order') {

                let grouped_list = this.groupBy(list, 'draft_guid')
                let keys_list = Object.keys(grouped_list)

                let arr = []

                for (let i = 0; i < keys_list.length; i++) {
                    // const element = Object.keys(grouped_list)[index];
                    let ord = { ...grouped_list[keys_list[i]][0] }

                    if (grouped_list[keys_list[i]].length > 1) {
                        if (!this.document.selected_pos_warehouse.warehouse_id) {
                            ord.details = [...grouped_list[keys_list[i]]]
                        }
                        else {
                            ord.details = [...grouped_list[keys_list[i]].filter(b => b.warehouse_guid == this.document.selected_pos_warehouse.warehouse_id)]
                            if (ord.details.length > 0){
                                ord.warehouse_guid = ord.details[0].warehouse_guid
                                ord.warehouse_name = ord.details[0].warehouse_name
                            }
                        }
                        ord.has_suborders = true
                    }
                    arr.push(ord)
                }
                if (this.document.selected_pos_warehouse.warehouse_id) {
                    arr = arr.filter(a => a.warehouse_guid == this.document.selected_pos_warehouse.warehouse_id)
                }
                list = [...arr]
            }
            return list;
        }
    },
    // onBeforeDestroy() {
    //     socket.disconnect();
    // },
}
</script>

<style>
.document-table * {
    font-family: 'Karla', sans-serif, FontAwesome;

}

.arrow-icon {

    display: inline-block;
    transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);


}

.fraction-wrap {
    background: #dedede;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 11px;
}
</style>
