<template>
    <div class="is-flex" style="padding-left: 37px;">
        <div @click.prevent="$parent.$parent.create_new_draft(document[document.current_doc_type + '_info'].pos_id)" 
            class="sales-nav-sections is-flex is-justify-content-center is-align-items-center mr-2 ml-1 bg-sky-100 is-clickable">
            <i class="fa-solid fa-bars"></i>
        </div>
        <div class="select-pos-nav bg-sky-100 mr-2 is-flex is-align-items-center px-4">
            <select-pos></select-pos>
        </div>
        <div v-if="document.current_doc_type == 'order'" class="select-pos-nav bg-sky-100 mr-2 is-flex is-align-items-center px-4">
            <label class="checkbox">
                <input type="checkbox" 
                    v-model="document.in_store"
                    true-value="1"
                    false-value="0"
                    class="ml-auto"
                >
                <span class="mr-1"> Ne vend </span>
            </label>
            <select-warehouse></select-warehouse>
        </div>
        <div v-if="document.current_doc_type == 'order' && document.selected_pos.guid && document.selected_pos_warehouse.warehouse_id" class="select-pos-nav bg-sky-100 mr-2 is-flex is-align-items-center px-4">
            <transport-group></transport-group>
        </div>

        <div class="bg-sky-100 sales-nav-steps-wrap px-3 py-2 is-flex is-align-items-center is-flex-grow-1" 
            :class="{'when_doc_selected' : document[document.current_doc_type + '_info'].id}">
            <!-- <div @click.prevent="$parent.$parent.create_new_draft()" class="sales-nav-item is-flex is-justify-content-center is-align-items-center">
                <i class="fa-solid fa-plus text-emerald-400"></i>
            </div> -->
            <div class="ml-3 mr-4">
                <span class="is-uppercase">
                    <!-- {{this.$route.path == '/sale/draft' ? 'Draft' : this.$route.path == '/sale/order' ? 'Porosi' : '' }}  -->
                    {{document.current_doc_type == 'draft' ? 'Draft' : document.current_doc_type == 'order' ? 'Porosi' : '' }} 
                </span>
                <span style="font-size:20px;">
                    {{document[document.current_doc_type + '_info'].id ? ' # ' + document[document.current_doc_type + '_info'].id : ''}}
                </span>
            </div>
            <div @click.prevent="open_modal" class="sales-nav-item is-flex is-justify-content-center is-align-items-center ml-2 has-text-dark">
                <document-count></document-count>
            </div>
            <div class="ml-auto">
                <sales-customers></sales-customers>
            </div>

        </div>
    </div>
</template>

<script>
import { $vfm } from 'vue-final-modal'
import DocumentNoteModal from './document-notes.vue'
import DocumentCount from './document-count.vue'
import salesCustomers from "./sales-customers";
import selectPos from "./select-pos";
import selectWarehouse from "./select-warehouse.vue";
import transportGroup from "./transport-group.vue";

import { useDocumentStore } from "@/stores/document";
import { storeToRefs } from 'pinia';
// import Api from "@/services/Api";
export default {
    components:{
        salesCustomers,
        selectPos,
        DocumentCount,
        selectWarehouse,
        transportGroup
    },
    methods: {
        open_modal() {
            $vfm.show({component: DocumentNoteModal})
        },
    },
    data() {
        return {
            document: storeToRefs(useDocumentStore()),

        }
    },
    created() {
    },

}
</script>

<style>

    .prod-list-price-col{
        min-width:120px;
    }
    .sales-nav-steps-wrap{
        /* min-width: 50%; */
        /* background: bisque; */
        display: flex;
        width: fit-content;
        height:35px;
    }
    .sales-nav-item{
        width:30px;
        height: 30px;
        background:#fff;
        border:solid 1px #dedede;
        border-radius: 50%;
        font-size:13px;
        cursor: pointer;
        margin-right:10px;
        position:relative;
        
    }
    .count-notify{
        width: 20px;
        height: 20px;
        font-size: 10px;
        border: solid 1px #fff;
        border-radius: 50%;
        position: absolute;
        top: -9px;
        right: -14px;
        color: #000;
    }
    .requested-count-notify{
        width: 20px;
        height: 20px;
        font-size:10px;
        border:solid 1px #fff;
        border-radius:50%;
        position:absolute;
        bottom:-9px;
        right:21px;
        color:#000;
    }
    .sales-nav-sections{
        width: 45px;
        height: px;
        /* border: solid 1px #dbdbdb; */
        /* border-radius: 5px; */
    }
    .select-pos-nav{
        height: 35px;
    }
    .when_doc_selected {
        background: #646f9b;
        color:#fff;
    }
</style>