<template>
    <div>
        <div v-if="$parent.$parent.document[$parent.$parent.document.current_doc_type + '_cart'][index] " class="">
            <p v-if="$parent.$parent.document[$parent.$parent.document.current_doc_type + '_cart'][index].edit_amount" class="m-0 has-text-right">
                <span @click.prevent="cancel_manual_amount()" class="delete is-small " style="position "></span>
            </p>
            <div v-if="!$parent.$parent.document[$parent.$parent.document.current_doc_type + '_cart'][index].edit_amount" 
                class="is-flex is-justify-content-space-between mt-1 mb-2 is-align-items-center">
                <a @click.prevent="change_amount('-', $parent.$parent.document[$parent.$parent.document.current_doc_type + '_cart'][index])" href="" 
                    class="product-amount-btn button is-small px-2">
                    <i class="fa-solid fa-minus"></i>
                </a>
                <span @click="$parent.$parent.document[$parent.$parent.document.current_doc_type + '_cart'][index].edit_amount = true" 
                    class="text-slate-400 mx-2 is-clickable">
                    {{amount_filter($parent.$parent.document[$parent.$parent.document.current_doc_type + '_cart'][index].amount)}}
                </span>
                <a @click.prevent="change_amount('+', $parent.$parent.document[$parent.$parent.document.current_doc_type + '_cart'][index])" href="" 
                    class="product-amount-btn button is-small px-2">
                    <i class="fa-solid fa-plus"></i>
                </a>
            </div>
            <div v-else-if="$parent.$parent.document[$parent.$parent.document.current_doc_type + '_cart'][index].edit_amount" 
                class="is-flex is-justify-content-space-between mt-1 mb-2 is-align-items-center">
                <div class="field has-addons has-addons-centered">
                    <div class="control">
                        <div class="field">
                            <div class="control has-icons-right">
                                <input @keyup.enter="save_manual_amount()" v-model="$parent.$parent.document[$parent.$parent.document.current_doc_type + '_cart'][index].amount" 
                                type="text" class="input is-small" style="width:80px;">
                                <span class="icon is-right">
                                    {{ $parent.$parent.document[$parent.$parent.document.current_doc_type + '_cart'][index].unit_symbol }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <p class="control">
                        <a @click.prevent="save_manual_amount()" class="button is-primary is-small">
                            <i class="fa-solid fa-check"></i>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { inject } from "vue";
var numeral = require('numeral');
export default {
    props: ['index'],
    setup() {
        return {
            products_cart: inject('products_cart')
        }
    },
    created(){
        this.$parent.$parent.document[this.$parent.$parent.document.current_doc_type + '_cart'] = [...this.$parent.$parent.document[this.$parent.$parent.document.current_doc_type + '_cart'].map(p => {
            p = {...p,
                    edit_amount: false,
                    amount: numeral(p.amount).format('0.00')
                }
            return p
        })]
    },
    methods: {
        change_amount(o, p){
            if(o == '-' && parseInt(p.amount) == 1) return
            this.$parent.$parent.document[this.$parent.$parent.document.current_doc_type + '_cart'] = [...this.$parent.$parent.document[this.$parent.$parent.document.current_doc_type + '_cart'].map(pp => {
                if(pp.product_id == p.product_id){
                    if(o == '+'){
                        pp.amount = parseFloat(pp.amount) + 1.00
                    } else if(o == '-'){
                        pp.amount = parseFloat(pp.amount) - 1.00
                    }
                    // o == '+' ? pp.amount = parseFloat(pp.amount) + 1 : o == '-' ? pp.amount = parseFloat(pp.amount) - 1 : ''
                    pp.price = pp.original_price * parseFloat(pp.amount)
                }
                return pp
            })]
            this.$parent.$parent.create_invoice_draft()
        },
        amount_filter(price){
            // var unit_name = this.$parent.$parent.document.draft_cart[this.index].unit == 1 ? 'x' : this.$parent.$parent.document.draft_cart[this.index].unit_name
            return numeral(price).format('0.00') + ' ' + this.$parent.$parent.document[this.$route.params.document + '_cart'][this.index].unit_symbol
        },
        save_manual_amount(){
            if(parseFloat(this.$parent.$parent.document[this.$parent.$parent.document.current_doc_type + '_cart'][this.index].amount) > 0){
                this.$parent.$parent.create_invoice_draft()
            }
        },
        cancel_manual_amount(){
            this.$parent.$parent.document[this.$parent.$parent.document.current_doc_type + '_cart'] = [...this.$parent.$parent.document[this.$parent.$parent.document.current_doc_type + '_cart'].map(p => {
                p = {...p,
                        edit_amount: false,
                        amount: p.amount_original
                    }
                return p
            })]
        }
    },

}
</script>

<style>
    .product-amount-btn{
        height: 26px;
        padding: 1px 5px!important;
        width: 20px;
    }

</style>