<template>
    <div class="modal is-active">
        <div @click.self="$emit('toggle_transport_modal')" class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Transport modal</p>
                <button @click.prevent="$emit('toggle_transport_modal')" class="delete" aria-label="close"></button>
            </header>
            <section class="modal-card-body px-0 pb-0">
                <pre>
                    {{$parent.$parent.document}}
                </pre>
            </section>
            <footer class="modal-card-foot">
                
            </footer>
        </div>
    </div>
</template>

<script>
import Api from "@/services/Api";
export default {
    created(){
        this.get_transport_modal_data()
    },
    methods: {
        get_transport_modal_data(){
            // document.selected_pos.guid && document.selected_pos_warehouse.warehouse_id
            Api(true).post("/transport/modal/data", {
                pos_id: this.$parent.$parent.document.selected_pos.guid,
                warehouse_id: this.$parent.$parent.document.selected_pos_warehouse.warehouse_id
            })
        }
    },

}
</script>

<style>

</style>