<template>
    <div>
        <!-- <pre> {{$parent.document.assigned_pos_warehouses}} </pre> -->
        <div v-if="$parent.document.assigned_pos_warehouses.length > 0" class="select is-small">
            <select v-model="selected_warehouse" @change="set_selected_pos_warehouse(selected_warehouse)">
                <option :value="-1">Zgjidh magazine</option>
                <option v-for="w ,index in warehouse_list" :key="index"
                    :value="w.warehouse_id"
                >{{w.warehouse_name}}</option>
            </select>
        </div>
        <!-- <pre>{{this.$parent.document.assigned_pos_warehouses}}</pre> -->
    </div>
</template>

<script>
// import { storeToRefs } from 'pinia';
// import { useDocumentStore } from "@/stores/document";

export default {
    data() {
        return {
            selected_warehouse: null,
        }
    },
    created(){
        // this.get_warehouses_list()
    },
    computed: {
        warehouse_list(){
            var list = this.$parent.document.assigned_pos_warehouses
            if(this.$parent.document.in_store == 1){
                list = list.filter(w => w.same_location == 1)
            }
            return list
        }
    },
    methods: {
        set_selected_pos_warehouse(sw){
            if(sw == -1){
                this.$parent.document.selected_pos_warehouse = {}
            } 
            else{
                let new_warehouse = this.$parent.document.assigned_pos_warehouses.filter(a => a.warehouse_id == sw)
                this.$parent.document.selected_pos_warehouse = new_warehouse[0]
            }
        }
    },

}
</script>

<style>

</style>