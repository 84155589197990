<template>
    <div>
        <div class="is-flex is-clickable" @click.prevent="toggle_transport_modal()">
            <div class="px-2 text-slate-500 bg-amber-200">
                <i class="fa-solid fa-list mr-3"></i>
                <span>3</span>
            </div>
            <div class="px-2 text-slate-500 bg-green-200">
                <i class="fa-solid fa-truck mr-3"></i>
                <i class="fa-solid fa-plus"></i>
            </div>
        </div>
        <transport-modal
            v-if="show_transport_modal"
            @toggle_transport_modal="toggle_transport_modal"
        ></transport-modal>
    </div>
</template>

<script>
import transportModal from "./transport-modal.vue";
export default {
    components:{
        transportModal
    },
    data() {
        return {
            show_transport_modal: false
        }
    },
    methods: {
        toggle_transport_modal(){
            this.show_transport_modal = !this.show_transport_modal

            // if(this.show_stock_info_modal) {
            //     this.selected_stock_product = p
            //     this.selected_stock_warehouse = w
            // }
            // else {
            //     this.selected_stock_product = {}
            //     this.selected_stock_warehouse = {}
            // }
        },
    },

}
</script>

<style>

</style>